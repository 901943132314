import React, { useState, useEffect, Fragment } from 'react';
import loginImg from '../assets/login-signup.jpg';
import logo from '../assets/Logo.svg';
import Input from '../components/Input';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { register } from '../redux/slices/authSlice';

const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const signUpSuccess = useSelector((state) => state.auth.signUpSuccess);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phoneNumber: '',
    address: '',
    practices: [],
  });

  const [isMatch, setIsMatch] = useState(true);
  const practiceOptions = ['Compost', 'BioChar', 'Cover Crops'];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phoneNumber') {
      const numericValue = value.replace(/\D/g, '');
      setFormData({ ...formData, [name]: numericValue });
    } else if (name === 'practices') {
      const isChecked = e.target.checked;
      const practice = e.target.value;
      setFormData((prevData) => {
        if (isChecked) {
          return { ...prevData, practices: [...prevData.practices, practice] };
        } else {
          return {
            ...prevData,
            practices: prevData.practices.filter((p) => p !== practice),
          };
        }
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    dispatch(register(formData));
  };

  if (signUpSuccess) {
    navigate('/login');
  }

  useEffect(() => {
    if (formData.password !== confirmPassword) {
      setIsMatch(false);
    } else {
      setIsMatch(true);
    }
  }, [confirmPassword]);

  return (
    <Fragment>
      <ToastContainer autoClose={2000} />
      <div className="grid grid-cols-1 sm:grid-cols-2 w-full">
        <div className="hidden sm:block">
          <img className="w-full h-full object-cover" src={loginImg} alt="" />
        </div>

        <div className="bg-gray-100 flex flex-col justify-center">
          <form
            onSubmit={handleSignup}
            className="max-w-[500px] w-full mx-auto bg-white p-4"
          >
            <Link to="/">
              <img src={logo} />
            </Link>
            <div className="flex-col">
              <div className="px-5">
                <Input
                  name="firstName"
                  type="text"
                  label="First Name:"
                  value={formData.firstName}
                  onChange={handleInputChange}
                />
                <Input
                  name="email"
                  type="email"
                  label="Email:"
                  value={formData.email}
                  onChange={handleInputChange}
                />
                <Input
                  name="password"
                  type="password"
                  label="Password"
                  value={formData.password}
                  onChange={handleInputChange}
                />
                <Input
                  name="confirmPassword"
                  type="password"
                  label="Confirm Password:"
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                />
                {!isMatch && (
                  <p className="text-red-500 text-sm mt-2">
                    {' '}
                    Password did not match
                  </p>
                )}
                <Input
                  name="address"
                  type="text"
                  label="Address:"
                  value={formData.address}
                  onChange={handleInputChange}
                />
              </div>
              <div className="px-5">
                <Input
                  name="lastName"
                  type="text"
                  label="Last Name:"
                  value={formData.lastName}
                  onChange={handleInputChange}
                />
                <Input
                  name="phoneNumber"
                  type="tel"
                  label="Phone Number:"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                />

                {/* Practices */}
                <div className="flex flex-col py-2">
                  <label>Practices :</label>
                  {practiceOptions.map((practice) => (
                    <label key={practice}>
                      <input
                        className="border px-8"
                        type="checkbox"
                        name="practices"
                        value={practice}
                        onChange={handleInputChange}
                        checked={formData.practices.includes(practice)}
                      />
                      {practice}
                    </label>
                  ))}
                </div>
              </div>
            </div>

            <button
              disabled={!isMatch}
              type="submit"
              className={
                'border w-full my-5 py-2 bg-lime-700 ' +
                (isMatch ? 'text-white hover:bg-lime-600' : 'text-red-500')
              }
            >
              Sign Up
            </button>
            <Link to="/login">
              <button
                className={
                  'border w-full my-5 py-2 bg-sky-500 text-white hover:bg-sky-300'
                }
              >
                Login
              </button>
            </Link>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default Signup;
