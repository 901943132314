import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import api from '../../api';

export const getAllEquipment = createAsyncThunk(
  'equipment/getAllEquipment',
  async (_, { rejectWithValue }) => {
    try {
      const res = await api.get('/equipments');
      return res.data.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const getSingleEquipment = createAsyncThunk(
  'equipment/getSingleEquipment',
  async (equipmentId, { rejectWithValue }) => {
    try {
      const res = await api.get(`/equipments/${equipmentId}`);
      return res.data.data.equipment;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

const initialState = {
  equipments: [],
  loading: false,
  totalPages: null,
  selectedEquipment: null,
  error: null,
};

const equipmentSlice = createSlice({
  name: 'equipment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // getAllEquipment 
      .addCase(getAllEquipment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllEquipment.fulfilled, (state, action) => {
        state.loading = false;
        state.equipments = action.payload;
      })
      .addCase(getAllEquipment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //  getSingleEquipment 
      .addCase(getSingleEquipment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSingleEquipment.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedEquipment = action.payload;
      })
      .addCase(getSingleEquipment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default equipmentSlice.reducer;
