import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { toast } from 'react-toastify';

/**Login with email password*/
export const loginRequest = createAsyncThunk(
  'auth/loginRequest',
  async ({ email, password }) => {
    try {
      const res = await api.post('auth/login', { email, password });
      // toast.success(`Welcome back!`);
      return res.data.data;
    } catch (error) {
      // toast.error(error.errors.message);
      throw error;
    }
  },
);

/**Register new account*/
export const register = createAsyncThunk('auth/register', async (formData) => {
  try {
    const res = await api.post('/users', formData);
    toast.success(`Thank you for your registration, ${formData.firstName}!`);
    return res.data.data;
  } catch (error) {
    // toast.warn(error.errors.message);
    throw error;
  }
});

/**Get current user infomation*/
export const getCurrentUser = createAsyncThunk(
  'auth/getCurrentUser',
  async () => {
    try {
      const res = await api.get(`/myself`);
      return res.data.data;
    } catch (error) {
      // toast.warn(error.errors.message);
      throw error;
    }
  },
);

const initialState = {
  user: {},
  users: [],
  isAuthenticated: null,
  loading: false,
  messages: [],
  acessToken: '',
  signUpSuccess: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logoutRequested: (state) => {
      localStorage.removeItem('token');
      delete api.defaults.headers.common['Authorization'];
      return { ...state, isAuthenticated: false };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginRequest.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(loginRequest.fulfilled, (state, action) => {
        return {
          ...state,
          user: action.payload.user,
          accessToken: action.payload.accessToken,
          loading: false,
          isAuthenticated: true,
        };
      })
      .addCase(loginRequest.rejected, (state) => {
        return { ...state, loading: false, isAuthenticated: false };
      })
      .addCase(register.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(register.fulfilled, (state) => {
        return { ...state, loading: false, signUpSuccess: true };
      })
      .addCase(register.rejected, (state) => {
        return { ...state, loading: false };
      })
      .addCase(getCurrentUser.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        return {
          ...state,
          user: action.payload.user,
          loading: false,
          isAuthenticated: true,
        };
      })
      .addCase(getCurrentUser.rejected, (state) => {
        return { ...state, loading: false, isAuthenticated: false };
      });
  },
});

export const { logoutRequested } = authSlice.actions;

export default authSlice.reducer;
